<template>
  <div>
    <el-table
      :data="spu.skus"
      border
      :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
    >
      <el-table-column
        type="index"
        label="序号"
        header-align="center"
        align="center"
        width="50"
      >
      </el-table-column>
      <template v-for="(item, index) in rArrs">
        <el-table-column
          :prop="item.prop"
          header-align="center"
          align="center"
          :label="item.label"
          :key="index"
          :width="item.width"
        >
          <template slot-scope="scope">
            <span v-if="item.prop === 'image'">
              <el-popover placement="right" trigger="hover">
                <img
                  :src="
                    scope.row[item.prop].length > 0
                      ? scope.row[item.prop]
                      : backImg
                  "
                  style="width: 200px; height: 200px"
                />
                <img
                  slot="reference"
                  :src="
                    scope.row[item.prop].length > 0
                      ? scope.row[item.prop]
                      : backImg
                  "
                  class="sku_image"
                />
              </el-popover>
            </span>
            <span v-else>
              {{ scope.row[item.prop] }}
            </span>
          </template>
        </el-table-column>
      </template>

      <el-table-column label="操作" header-align="center" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="showChannelPriceDialog(scope.row)"
            >设置渠道价格</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <channel-price ref="channelPrice"></channel-price>
  </div>
</template>
<script>
import backImg from "@/assets/logofang.png";
import ChannelPrice from "./channelPrice";

export default {
  name: "Main",
  components: { ChannelPrice },
  props: {
    spu: {
      type: Object,
    },
  },
  data() {
    return {
      backImg: backImg,
      role: null,
      tableData: [],
      rArrs: [
        {
          prop: "image",
          label: "图片",
          width: 50,
        },
        {
          prop: "skuName",
          label: "规格",
        },
        {
          prop: "skuCode",
          label: "规格编码",
          width: 200,
        },
        {
          prop: "barCode",
          label: "国标条码",
        },
        {
          prop: "linePrice",
          label: "划线价(元)",
        },
        {
          prop: "salePrice",
          label: "供货价(元)",
        },
        {
          prop: "costPrice",
          label: "成本价(元)",
        },
        {
          prop: "weight",
          label: "重量(千克)",
          width: 120,
        },
      ],
    };
  },
  methods: {
    tableMoney(num, names, n) {
      var that = this;
      num = num || 0;
      var nums = parseFloat(num).toFixed(2);
      that.tableData[n][names] = nums || that.tableData[n][names];
    },
    showChannelPriceDialog(sku) {
      this.$refs.channelPrice.show(sku);
    },
  },
  mounted() {},
};
</script>
<style scoped>
.sku_image {
  width: 35px;
  height: 35px;
}
</style>