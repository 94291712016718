<template>
  <el-dialog
    :title="title"
    :visible.sync="flag"
    :before-close="handleClose"
    :modal-append-to-body="false"
    width="800px"
  >

    <span class="note"><b>商品价格说明：</b>指定渠道价优先级最高，其次等级价格。如果以上两者都没有设置，则默认使用商品供货价跟渠道结算。</span>
    <div class="line"></div>

    <el-tabs v-model="activeName" @tab-click="handleTab">
      <el-tab-pane label="渠道价格" name="channel"></el-tab-pane>
      <el-tab-pane label="等级价格" name="level"></el-tab-pane>
    </el-tabs>


    <div class="line"></div>
    <div v-if="activeName == 'level'">
      <el-switch
        v-model="sku.multiSalePrice"
        active-color="#13ce66"
        inactive-color="#3f3f3f"
        active-text="开启等级价格"
        :active-value="1"
        :inactive-value="0"
      >
      </el-switch>
      <el-button
        type="primary"
        size="small"
        style="margin-left: 500px"
        @click="gotoLevelPage"
        >设置渠道等级</el-button
      >
      <div class="line"></div>
      <el-table
        v-if="sku.multiSalePrice == 1"
        border
        :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
        stripe
        :data="levels"
      >
        <el-table-column prop="name" label="等级"> </el-table-column>
        <el-table-column prop="code" label="编码"> </el-table-column>
        <el-table-column prop="costPrice" label="成本价（元）" width="150">
        </el-table-column>
        <el-table-column prop="salePrice" label="供货价（元）" width="150">
        </el-table-column>
        <el-table-column
          prop="price"
          label="指定供货价（单位元，可置空不设置）"
          width="230"
        >
          <template slot-scope="scope">
            <el-input style="width: 150px" v-model="scope.row.price"></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-if="activeName == 'channel'">
      <div class="line"></div>

      <el-form>
        <el-form-item
          label="请选择渠道："
          required
          label-width="50"
          style="float: left; width: 350px"
        >
          <el-select
            v-model="channelName"
            placeholder="请选择渠道"
            clearable
            @change="onChannelChange"
            filterable
            @clear="onChannelClear"
            :filter-method="channelNameFilter"
          >
            <el-option
              v-for="item in groups"
              :key="item.id"
              :label="item.name"
              :value="item.targetOrgId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="指定渠道价格(元)："
          style="float: left; width: 300px"
          label-width="50"
        >
          <el-input
            v-model="channelPrice"
            placeholder="请输入渠道价格"
            style="width: 150px"
          ></el-input>
        </el-form-item>
        <el-button
          style="float: left; margin-left: 10px"
          type="primary"
          size="small"
          @click="addChannel"
          icon="el-icon-plus"
          >添加</el-button
        >
      </el-form>

      <el-table
        border
        :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
        stripe
        :data="channels"
      >
        <el-table-column prop="targetOrgName" label="渠道/团长">
        </el-table-column>
        <el-table-column prop="costPrice" label="成本价（元）" width="150">
          <template>
            {{ sku.costPrice }}
          </template>
        </el-table-column>
        <el-table-column prop="salePrice" label="供货价（元）" width="150">
          <template>
            {{ sku.salePrice }}
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          label="指定渠道价（单位元，可置空不设置）"
          width="230"
        >
          <template slot-scope="scope">
            <el-input style="width: 150px" v-model="scope.row.price"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="100">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="deleteChannel(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="paginations">
        <el-pagination
          background
          layout="total, prev, pager, next, sizes"
          @current-change="changePage"
          :current-page="page"
          :page-size="pageSize"
          :page-sizes="[20]"
          :total="total"
        >
        </el-pagination>
      </div>

      <div class="line"></div>

      <el-form>
        <el-form-item
          label="统一设置渠道价："
          label-width="100"
          label-position="left"
        >
          <el-input
            v-model="allInOnePrice"
            style="width: 200px"
            @blur="refreshPrice"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class="dialog-footer" slot="footer" v-if="activeName == 'level'">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="saveLevel">保存</el-button>
    </div>

    <div class="dialog-footer" slot="footer" v-if="activeName == 'channel'">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="saveChannel">保存</el-button>
    </div>
  </el-dialog>
</template>
<script>
import backImg from "@/assets/logofang.png";
import { getLevel } from "@/api/level.js";
import {
  getSkuLevelPrice,
  saveSkuLevelPrice,
  saveSkuChannelPrice,
  getChannelPrice,
  deleteSkuChannelPrice,
} from "@/api/skuLevelPrice.js";
import { myRetailer } from "@/api/common.js";

export default {
  name: "Main",
  data() {
    return {
      backImg: backImg,
      sku: {
        multiSalePrice: 0,
      },
      title: "",
      flag: false,
      activeName: "channel",
      levels: [],
      channels: [],
      groups: [],
      allInOnePrice: "",
      channelName: "",
      channelPrice: "",
      channelId: "",
      page: 1,
      pageSize: 20,
      total: 0,
    };
  },
  methods: {
    show(sku) {
      console.log("sku", sku);
      this.sku = sku;
      this.flag = true;
      this.title = this.sku.spuName + " ~ " + this.sku.skuName;
      this.page = 1;
      this.getLevel();
      this.getChannel();
      this.getChannelPrice();
    },
    gotoLevelPage() {
      this.$router.push({
        path: "/level/of/retailer",
      });
    },
    getChannel() {
      this.channelNameFilter("");
    },
    deleteChannel(item) {
      deleteSkuChannelPrice(item).then((res) => {
        if (res.code === 200) {
          this.getChannelPrice();
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    channelNameFilter(val) {
      const that = this;
      that.channelName = val;
      const param = {
        name: val,
        page: 1,
        pageSize: 20,
        orderByCreated: "DESC",
      };
      myRetailer(param).then((res) => {
        if (res.code === 200) {
          that.groups = res.data.rows;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    addChannel() {
      if (this.channelId == "" || this.channelPrice == "") {
        this.$message.error("请选择渠道，并填写该渠道指定价格");
        return;
      }
      const param = {
        skuId: this.sku.id,
        targetOrgId: this.channelId,
        price: this.channelPrice,
      };
      saveSkuChannelPrice({ entityList: [param] }).then((res) => {
        if (res.code === 200) {
          this.getChannelPrice();
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    refreshPrice() {
      let channels = this.channels;
      channels.forEach((c) => {
        c.price = this.allInOnePrice;
      });
      this.channels = channels;
    },
    onChannelChange(e) {
      this.channelId = e;
    },
    onChannelClear() {
      this.channelName = "";
      this.channelNameFilter(this.channelName);
    },
    changePage(e) {
      this.page = e;
      this.getChannelPrice();
    },
    getChannelPrice() {
      const param = {
        skuId: this.sku.id,
        page: this.page,
        pageSize: this.pageSize,
        orderByCreated: "DESC",
      };
      const that = this;
      getChannelPrice(param).then((res) => {
        if (res.code === 200) {
          let channels = res.data.rows;
          that.channels = channels;
          that.total = res.data.totalRows;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getLevel() {
      const param = {
        role: "supplier",
        orderByCreated: "DESC",
        pageNo: 1,
        pageSize: 20,
      };
      const that = this;
      getLevel(param).then((res) => {
        if (res.code === 200) {
          let levels = res.data.rows;
          levels.forEach((p) => {
            p.price = null;
            p.salePrice = that.sku.salePrice;
            p.costPrice = that.sku.costPrice;
          });
          that.levels = levels;

          getSkuLevelPrice({ skuId: that.sku.id }).then((response) => {
            if (response.code === 200) {
              that.levels.forEach((level) => {
                response.data.forEach((it) => {
                  if (it.levelId == level.id) {
                    level.price = it.price;
                  }
                });
              });
            } else {
              that.$message.error(res.message);
            }
          });
        } else {
          that.$message.error(res.message);
        }
      });
    },
    handleTab(tab, event) {
      this.activeName = tab.name;
    },
    handleClose() {
      this.flag = false;
      this.title = null;
      this.levels = [];
      this.allInOnePrice = "";
      this.activeName = "channel";
      this.channelName = "";
      this.channelPrice = "";
    },
    saveLevel() {
      let params = [];
      this.levels.forEach((level) => {
        const param = {
          skuId: this.sku.id,
          enableMultiSalePrice: this.sku.multiSalePrice,
          levelId: level.id,
          price: level.price,
        };
        params.push(param);
      });
      const obj = {
        entityList: params,
      };

      saveSkuLevelPrice(obj).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.handleClose();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    saveChannel() {
      saveSkuChannelPrice({ entityList: this.channels }).then((res) => {
        if (res.code === 200) {
          this.getChannelPrice();
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  mounted() {},
};
</script>
<style scoped>
.line {
  height: 15px;
  display: block;
}
.additem {
  width: 32px;
  height: 32px;
  display: block;
  float: right;
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  background-color: #07c160;
  margin-left: 10px;
  cursor: pointer;
}
.note {
  color: red;
  font-size: 12px;
}
</style>