<template>
  <div>
    <div class="contents">
     <div class="tableSearch">
        <div class="biaoticss"><span><el-button size="medium" type="primary" @click="addfun" icon="el-icon-plus">手动添加商品</el-button></span><strong>您的商品库中已有 {{total}} 件商品</strong></div> 
        <div class="classFun">
          <div class="classObj" @click="addClass"><i class="iconfont icon-shezhi"></i>分类管理</div>
          <strong>商品分类</strong>
          <span :class="classCur==0?'xuan':''" @click="clickClass(0)">全部</span>
          <template>
          <span v-for="item in classArr" @click="clickClass(item.id)" :class="classCur==item.id?'xuan':''">{{item.name}}</span>
          </template>
        </div>
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="70px">
            <el-form-item label="价格区间" style="margin-bottom:0px">
              <el-select v-model="searchForm.priceRange" clearable  placeholder="全部">
                <el-option v-for="(itema, index) in priceCode" :key="index" :label="itema.value" :value="itema.code"></el-option>
                <!-- <el-option label="0~5元" value="0~5"></el-option>
                <el-option label="5~20元" value="5~20"></el-option>
                <el-option label="20~100元" value="20~100"></el-option>
                <el-option label="100~500元" value="100~500"></el-option>
                <el-option label="大于500元" value="500~∞"></el-option> -->
              </el-select>
            </el-form-item>
             <!-- <el-form-item label="状态" style="margin-bottom:0px">
              <el-select v-model="searchForm.saleStatus" clearable  placeholder="全部">
                <el-option label="在售" value="1"></el-option>
                <el-option label="下架" value="0"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="商品" style="margin-bottom:0px">
              <el-input v-model="searchForm.searchValue" clearable placeholder="请输入商品名称/商品编码" style="width:250px"></el-input>
            </el-form-item>
            <el-button @click="createSearch" type="primary" icon="el-icon-search">查询</el-button>
            <el-button @click="chongzhi">重置</el-button>
          </el-form>
        </div>

      <div class="mainbox">
        <el-tabs v-model="activeName" @tab-click="handleTabClick" >
          <el-tab-pane label="在售中" name="1">

            <div class="buttons">
                <el-button type="primary" plain @click="copyToRetailer" v-if="role=='supplier'">同步到团长端</el-button>
            </div>

          </el-tab-pane>
          <el-tab-pane label="已下架" name="0">
          </el-tab-pane>
          <el-tab-pane label="全部商品" name="full">
          </el-tab-pane>
        </el-tabs>

       <el-table
        :data="tableData"
        border
        @selection-change="handleSpuSelect"
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
         <el-table-column
          type="selection"          
          width="40" header-align="center" align="center">
        </el-table-column>

         <el-table-column
          prop="sort"
          label="排序（从小到大）"
          width="80" header-align="center" align="center">
          <template slot-scope="scope">
              <el-input v-model="scope.row.sort" @blur="changeSort(scope.row)"></el-input>
          </template>
        </el-table-column>

        <el-table-column
          prop="name"
          label="商品名称">
          <template slot-scope="scope">
            <el-popover placement="right" trigger="hover">
                  <img
                    :src="scope.row.images.length>0 ? scope.row.images[0] : backImg"
                    style="width: 60px; height: 60px"
                  />
                  <img
                    slot="reference"
                    :src="scope.row.images.length>0 ? scope.row.images[0] : backImg"
                    class="imgSize"
                  />
                </el-popover>
            <strong class="biaoti">{{scope.row.name}}</strong>
            <p class="price">供货价：
              <strong>
                ￥{{scope.row.skus[0].salePrice}}
              </strong>
            </p>
            <!-- <p>编码：{{scope.row.code}}</p> -->
          </template>  
        </el-table-column>
        <el-table-column
          label="状态" width="70" header-align="center" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.saleStatus==1">在售</el-tag>
            <el-tag type="warning" v-if="scope.row.saleStatus==0">下架</el-tag>
            <!-- <el-switch v-model="scope.row.saleStatus==1?true:false" active-color="#13ce66"></el-switch> -->              
          </template>  
        </el-table-column>
        <el-table-column
          prop="shelfTime"
          label="上下架时间" width="150" header-align="center" align="center">
        </el-table-column>

        <el-table-column
          prop="purchaserName"
          label="采购负责人" width="100"  header-align="center" align="center">
        </el-table-column>

        <el-table-column
          prop="deliveryTemplateName"
          label="运费模板" width="100" header-align="center" align="center">
        </el-table-column>

        <el-table-column
          prop="createTime"
          label="创建时间"
          width="150" header-align="center" align="center">
        </el-table-column>

        <el-table-column
          label="修改"
          type="expand"
          width="50" header-align="center" align="center">
          <template slot-scope="scope">
            <sku-list :spu="scope.row"></sku-list>
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          width="150" header-align="center" align="left">
          <template slot-scope="scope">
            <el-button @click="handleSku(scope.row, scope.$index)" class="btns" type="text" size="small">查看规格</el-button>
            <el-button @click="handlePoint(scope.row)" type="text" size="small">打印标签</el-button>
            <el-button @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
            <el-button v-if="scope.row.saleStatus==0" @click="handleON(scope.row)" type="text" size="small" >上架</el-button>
            <el-popconfirm @confirm="handleOFF(scope.row)" v-if="scope.row.saleStatus==1"  title="下架后将无法自动匹配订单中的商品，确定要下架商品吗？">
              <template #reference>
                <el-button  type="text" size="small" class="btns">下架</el-button>
              </template>
            </el-popconfirm>
            <el-button v-if="role=='supplier'" @click="handleOpen(scope.row)" type="text" size="small" class="btns">去开团</el-button>
            <el-button @click="handleSee(scope.row)" type="text" size="small">操作日志</el-button>
          </template>
    </el-table-column>
      </el-table>
      <div class="paginations">
           <el-pagination
          background
          layout="total, prev, pager, next, sizes"
          @current-change="changePage" :current-page="page" :page-size="pageSize"
          :page-sizes="[20, 40, 50, 100]"
          :total="total">
        </el-pagination>
      </div>
      <!-- </el-tab-pane>
    </el-tabs> -->
      </div>
    </div>
    <subClass :isShow="classflag" @onSuccess="onCancalFun" @onCancal="onCancalFun"></subClass>

    <el-dialog title="同步到团长端：" :visible.sync="copyDialogFlag" width="600px">
        <el-form label-position="right" label-width="200px">
            <el-form-item label="选择商品所属供应商：">
              <el-select v-model="supplier" 
              placeholder="请选择供应商" clearable filterable 
              :filter-method="suppliersFilter">
                    <el-option
                      v-for="item in suppliers"
                      :key="item.targetOrgId"
                      :label="item.name"
                      :value="item.targetOrgId"
                      :disabled="item.cooperation==0"
                      >
                </el-option>
              </el-select>
           </el-form-item>         
           <div style="font-size:12px;color:red;margin-left:100px;">提示：同步到团长端的商品默认是下架状态</div>
        </el-form>
            <div class="dialog-footer" slot="footer">           
              <el-button @click="() => copyDialogFlag=false">取消</el-button>
              <el-button type="primary" @click="copyit" :loading="syncing">立即同步</el-button>
            </div>
    </el-dialog>

    <sku-table ref="skuTable" @update="handleSpuUpdate"></sku-table>
    <devicelayer :isShow="isCollapse" :rowdata="pointData" @cancalfunc="cancalFuns"></devicelayer>
    <caozuoList :isShow="drawer" :rowdata="drawerTitle" @cancalfunc="cancalDraw"></caozuoList>
  </div>
</template>

<script>
import { getGoodList, offspu, onspu, getCategory, changeSort, copy2Retailer } from "@/api/good";
import { dictConfig } from "@/api/common";
import { listSupplier } from "@/api/supplier";
import passWord from "@/components/password"
import subClass from "@/components/getClass"
import backImg from "@/assets/logofang.png"
import skuTable from "./sku"
import skuList from "./skuList"
import devicelayer from "@/components/point";
import caozuoList from "@/components/caozuolishi";

export default {
  name: 'suppliMain',
  components: { passWord, subClass, skuTable, skuList, devicelayer, caozuoList},
  data() {
    return {
      searchForm: {
        categoryId: '', 
        supplierName: '',
        searchValue: '',
        saleStatus: '1',
        priceRange: null,
      },
      backImg: backImg,
      classArr: [],
      priceCode: [],
      classCur: 0,
      activeName: '1',
      classflag: false,
      isCollapse: false,
      tableData: [],
      pointData: {},
      total: 0,
      page:1,
      pageSize: 20,
      role: 'supplier',
      spuList: [],
      drawerTitle: {},
      drawer: false,
      copyDialogFlag: false,
      suppliers: [],
      supplier: null,
      syncing: false,
    }
  },
  methods: {
    handleSpuUpdate(spu) {
      for(var i =0; i< this.tableData.length;i++)  {
        if(this.tableData[i].id == spu.id) {
          this.tableData[i] = spu
          this.$set(this.tableData, i, spu)
        }
      }
    },
    handleTabClick(tab, event) {
      this.tableData = []
      this.getTopSearch()
    },
    handleSku(spu, index) {
      this.$refs.skuTable.show(spu, (spu) => this.tableData[index] = spu)
    },
    copyit() {
      const param = {
          "supplierOrgId": this.supplier,
          "spuIds": this.spuList,
      }
      this.syncing = true
      copy2Retailer(param).then(res=>{
        this.syncing = false
        if(res.code===200) {
          this.$message.success(res.message)
          this.copyDialogFlag = false
        }else{
          this.$message.error(res.message)
        }
      }).catch(err => {
        this.syncing = false
      })
    },
    suppliersFilter(val) {
      const param = {
        "name": val,
      }
      listSupplier(param).then(res => {
        if(res.code===200) {
          this.suppliers = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleSpuSelect(spuList) {
      this.spuList = spuList.map(s => s.id)
    },
    copyToRetailer() {
      if(this.spuList.length==0) {
        this.$message.warning("请选择要同步的商品")
        return
      }
      this.suppliersFilter(null)
      this.copyDialogFlag = true
    },
    changeSort(row) {
      const param = {
        "id": row.id,
        "sort": row.sort
      }
      changeSort(param).then((res)=>{
        if(res.code ===200) {          
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handlePoint(row){
      this.isCollapse = true
      this.pointData = row
    },
    cancalFuns(e){
      this.isCollapse = e
    },
    handleON(row) {
      var objs = {
        id: row.id
      }
      onspu(objs).then(res => {
        console.log(res);
        if(res.code ===200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.getTopSearch()
        }else{
          this.$message.error(res.message)
        }
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
    handleOFF(row) {
      var objs = {
        id: row.id
      }
      offspu(objs).then(res => {
        console.log(res);
        if(res.code ===200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.getTopSearch()
        }else{
          this.$message.error(res.message)
        }
      })
    },
    addfun(){
      this.$router.push({
        path: "/goods/detail"
      })
    },
    handleOpen(row){
      this.$router.push({
        path: "/sendtuan/detail",
        query: {
          id: row.id, 
          goodname: row.name
        }
      })
    },
    getMaList(){
      var that = this
      var objs = {}
      objs.code = "spu_price_range"
      dictConfig(objs).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.priceCode = res.data.dictList
        }else{
          this.$message.error(res.message)
        }
      })
    },
    addClass(){
      this.classflag = true
    },
    onCancalFun(vv){
      this.classflag = vv
      this.getCategoryList()
    },
    clickClass(n){
      this.classCur = n
      this.getTopSearch()
    },
    getTopSearch(){
      var that = this
      var jiyidata = window.sessionStorage.getItem('searchForm');
     
      // if(jiyidata){
      //   that.searchForm = JSON.parse(jiyidata)
      // }

      if(this.searchForm.priceRange=="") {
        this.searchForm.priceRange = null
      }

      var datas = that.searchForm
      datas.saleStatus = that.activeName == 'full' ? null : that.activeName
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.orderByCreated = "DESC";
      datas.categoryId = that.classCur==0?'':that.classCur
      datas.role = that.role
      getGoodList(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.tableData = res.data.rows
          that.total = res.data.totalRows
          window.sessionStorage.setItem("searchForm", JSON.stringify(datas));
        }else{
          this.$message.error(res.message)
        }
      })
    },
    getCategoryList(){
      var that = this
      const param = {
        "role": this.role
      }
      getCategory(param).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.classArr = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handleEdit(row){
      console.log(row)
      this.$router.push({
        path: "/goods/detail/" + row.id
      })
    },
    handleDel(row){
      console.log(row)
    },
    createSearch() {
      this.page = 1
      this.getTopSearch()
    },
    chongzhi(){
      this.classCur = 0
      this.page = 1
      this.searchForm.categoryId = null
      this.searchForm.supplierName = null
      this.searchForm.searchValue = null
      this.searchForm.saleStatus = null
      this.searchForm.priceRange = null
    },
    cancalDraw(e){
      this.drawer = e 
    },
    handleSee(row){
      var that = this
      that.drawerTitle = row
      that.drawer = true
    },
  },
  mounted() {
    this.getMaList()
    this.getCategoryList()
    this.getTopSearch()
  }
}
</script>

<style scoped>

.biaoticss{
  padding-bottom: 15px;
  font-size: 18px;
  padding-left: 10px;
}
.biaoticss span{
  float:right
}
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.classFun{
  padding: 0px 12px 10px;
  overflow: hidden;
}
.classFun strong{
  font-size: 14px;
  font-weight: 350;
  color: #606266;
  padding-right: 12px;
}
.classFun span{
  padding: 5px 20px;
  border: 1px solid #eee;
  line-height: 44px;
  color: #333;
  margin-right: 10px;
  cursor: pointer;
}
.classFun span.xuan{
  background-color: #07C160;
  color: #fff;
  border-color: #07C160;
}
.classObj{
  float:right;
  line-height: 44px;
  font-size: 12px;
  color: #999;
  cursor: pointer;
}
.classObj i{
  font-size: 12px;
  padding-right: 5px;
}
.classList{
  padding-top: 10px;
}
.imgSize{
  float:left;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  margin-top: 4px;
  border: 1px solid #eee;
  background-color: #f3f3f3;
}
.biaoti{
  display: block;
  line-height: 18px;
  /* height: 36px; */
  overflow: hidden;
}
.price strong{
  color: #FF7200
}
.buttons {
    margin-bottom:10px;
}
.btns {
  margin-left: 10px;
}
.price {
  padding-left:40px;
}
</style>