import request from '@/api/request.js'


// 级别
export function getSkuLevelPrice (data) {
    return request({
      url: '/api/skuLevelPrice/sku',
      method: 'POST',
      data
    })
  }

// 设置等级价格
export function saveSkuLevelPrice (data) {
  return request({
    url: '/api/skuLevelPrice/batchSaveOrUpdate',
    method: 'POST',
    data
  })
}

// 查询渠道价格
export function getChannelPrice (data) {
  return request({
    url: '/api/skuRetailerPrice/page',
    method: 'POST',
    data
  })
}

// 设置渠道价格
export function saveSkuChannelPrice (data) {
  return request({
    url: '/api/skuRetailerPrice/batchSaveOrUpdate',
    method: 'POST',
    data
  })
}

// 删除渠道价格
export function deleteSkuChannelPrice (data) {
  return request({
    url: '/api/skuRetailerPrice/delete',
    method: 'POST',
    data
  })
}